import React from 'react';
import classNames from 'classnames';
import c from '../../home.Module.scss';
import Headings from '../../../Shared/Headings';
import Text from '../../../Shared/Text';
import { cardIconsInfo } from '../../../../helpers/Home/CapabilitiesToStudy';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';

const CapabilityCard = ({
  index,
  cardId,
  cardHead,
  dueDate = '2/25/2022',
  timeSpent = '15:00:02',
  handleClick,
  ...props
}) => {
  const history = useHistory();

  return (
    <div
      className={classNames(
        'py-3 px-4',
        c.capabilityCard,
        c[`cardMode${index}`]
      )}
    >
      <Headings headingType="h4" classToOverride="pb-1 fw-bold">
        {cardHead}
      </Headings>
      <Text
        textType="t1"
        classToOverride={classNames(
          c.fontWeight500,
          c.cardTextProp,
          c.cardDescDimension
        )}
      >
        {props?.children}
      </Text>
      <div className="row g-0 pt-2 align-items-end">
        {/* <div className="col-6">
          <Text textType="t1" classToOverride={c.fontWeight500}>
            <span>Due date : </span>
            <span className={c.cardTextProp}>{dueDate}</span>
          </Text>
          <Text textType="t1" classToOverride={c.fontWeight500}>
            <span>Time Spent : </span>
            <span className={c.cardTextProp}>{timeSpent}</span>
          </Text>
        </div> */}
        <div className="col-12 d-flex justify-content-end">
          {cardIconsInfo.map((item) => (
            <div
              key={item?.id}
              className="d-flex flex-column align-items-center"
            >
              <IconButton
                color="inherit"
                onClick={(event) =>
                  item?.id === 'completeIcon'
                    ? handleClick(event?.target?.id)
                    : history.push({
                        pathname: '/learning-plans',
                        search: `?capability_id=${cardId}`,
                      })
                }
              >
                <SvgIcon
                  id={cardId}
                  component={item?.iconName}
                  className={classNames(c.cardIconButton, 'rounded-circle')}
                />
              </IconButton>
              <Text textType="t3">{item?.downLabel}</Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CapabilityCard;
